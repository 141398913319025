<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      :permanent="$vuetify.breakpoint.mdAndUp || $route.name == 'Info'"
      :temporary="$vuetify.breakpoint.smAndDown"
    >
      <v-subheader>
        <v-list-item-action-text> インフォ </v-list-item-action-text>
      </v-subheader>
      <v-divider></v-divider>
      <v-list>
        <v-list-item :to="{ name: 'TermsOfUse' }">
          <v-list-item-content>
            <v-list-item-title>利用規約</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'PrivacyPolicy' }">
          <v-list-item-content>
            <v-list-item-title>プライバシーポリシー</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Update' }">
          <v-list-item-content>
            <v-list-item-title>更新情報</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Contact' }">
          <v-list-item-content>
            <v-list-item-title>ご意見</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item href="https://kaad.jp/" target="_blank">
          <v-list-item-content>
            <v-list-item-title>運営会社</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-card class="base">
      <v-card-actions>
        <v-btn
          v-if="!$vuetify.breakpoint.mdAndUp"
          @click="drawer = !drawer"
          color="secondary"
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </v-card-actions>
      <router-view></router-view>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {},
  metaInfo: {
    title: "インフォ",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "利用規約、プライバシーポリシー、運営会社を掲載しています。",
      },
    ],
  },
  mounted: function () {},
};
</script>

<style scoped>
.base {
  transition-duration: 0.15s;
  position: absolute;
  top: 0;
  transition-property: all;
  width: 100%;
  height: 100%;
  padding-bottom: 56px;
  overflow-y: auto;
}
</style>